class TemplateInput {

    constructor(input, isFrez, part = null) {
        this.id = input.xnc_template_symbol;
        this.name = input.name;
        this.firm_data = input?.firm_data ?? null;
        this.type = input.type;
        this.max = 0;
        this.min = 0;
        this.symbol = input.symbol;
        this.options = [];
        this.accepted = [];
        this.isFrez = isFrez;
        this.part = part;
        this.input = input;
        this.default_value = null;
        this.name_by_value = input?.name_by_value ?? null;


    }

    _getType() {
        switch (this.input.type) {
            case 'dig':
                this.type = 'number';
                break;
            case 'str':
                this.type = 'text';
                break;
            case 'bool':
                this.type = 'checkbox';
                break;
            case 'select':
                this.type = 'select';
                break;
        }
    }

    _getRanges() {
        let minValueDef = isNaN(this.input.min_value_result) ? eval(this._getPartSymbolResult(this.input.min_value_result)) : Number(this.input.min_value_result);
        let maxValueDef = isNaN(this.input.max_value_result) ? eval(this._getPartSymbolResult(this.input.max_value_result)) : Number(this.input.max_value_result);


        let firmDataMinValue = isNaN(this.firm_data?.value_min) && this.firm_data?.value_min !== undefined ? eval(this._getPartSymbolResult(this.firm_data?.value_min)) : Number(this.firm_data?.value_min ?? 0);
        let firmDataMaxValue = isNaN(this.firm_data?.value_max) && this.firm_data?.value_max !== undefined ? eval(this._getPartSymbolResult(this.firm_data?.value_max)) : Number(this.firm_data?.value_max ?? 0);

        let maxValue = (firmDataMaxValue > 0 && firmDataMaxValue < maxValueDef) ? firmDataMaxValue : maxValueDef;
        let minValue = (firmDataMinValue > minValueDef) && (firmDataMinValue <= maxValue)? firmDataMinValue : minValueDef;

        this.min = minValue;
        this.max = maxValue;
    }

    _getDefValue() {
        let default_value = this.input?.default_value ?? null;
        try {
            if (!!default_value) {
                this.default_value = eval(this._getPartSymbolResult(default_value));
            } else {
                this.default_value = null;
            }

        } catch (e) {
            this.default_value = default_value ?? null;
        }
    }

    _getOptions() {
        this.options = this.input.select_array.map(e => {
            return {
                value: e.id,
                label: e.text,
                image: e.pic ?? null,
                image_big: e.hasOwnProperty('pic_big') ? e.pic_big : null,
                hide: e?.hide ?? null
            }
        })
    }

    _getAccepted = (input, part) => {
        this.accepted = this.input.restriction_by_select ? this.input.restriction_by_select.map(e => {

            var new_max = 0;
            try {
                new_max = eval(this._getPartSymbolResult(e.max_value))
            } catch (e) {
                new_max = eval(this._getPartSymbolResult(e.max_value))
            }

            e.max_value = new_max ? new_max : 0;

            return e;
        }) : []
    }


    _getPartSymbolResult = (symbol) => {
        const safeSymbol = String(symbol || '');
        return safeSymbol.replace('detail.x2', this.part?.x2 || this.part?.x || this?.part?.x1)
            .replace('detail.x1', this.part?.x1 || this.part?.x || this.part?.x2)
            .replace('detail.x', this.part?.x || this.part?.x1 || this.part?.x2)
            .replace('detail.y2', this.part?.y2 || this.part?.y || this.part?.y1)
            .replace('detail.y1', this.part?.y1 || this.part?.y || this?.part.y2)
            .replace('detail.y', this.part?.y || this.part?.y1 || this.part?.y2)
            .replace('detail.z', this.part?.z)
            .replace('detail.small_side', (Number(this.part?.x1) > Number(this.part?.y1) ? this.part?.y1 : this.part?.x1))
    }


    transform() {
        this._getDefValue();
        this._getType();

        if (this.type !== 'select') this._getRanges();
        if (this.type === 'select') this._getOptions();
        if (this.type === 'number') this._getAccepted();

        return {
            id: this.id,
            default_value: this.default_value,
            name: this.name,
            name_by_value: this?.name_by_value,
            type: this.type,
            max: this.max,
            min: this.min,
            symbol: this.symbol,
            options: this.options,
            accepted: this.accepted,
            isFrez: this.isFrez,
            firm_data: this.firm_data
        }
    }


}

export default TemplateInput;