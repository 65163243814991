import React, {Component} from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error:null, errorInfo:null };
    }

    static getDerivedStateFromError(error) {
        console.log(error)
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
        console.log(error.stack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <>
                <h3>Something went wrong.
                </h3>
                <p>
                 stack:   {this.state?.error?.stack}</p>
            </>

        }

        return this.props.children;
    }
}

export default ErrorBoundary;